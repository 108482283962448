
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref
} from "vue";
import { useStore } from "vuex";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { capitalize, getLink } from "@/utils/utils";
import {
  UPDATE_PASSWORD_MUTATION,
  UPDATE_PROFILE_MUTATION
} from "../constants/gql/user";
import { FIND_TAGS } from "../constants/gql/tag";
import { FIND_CFT_QUERY } from "../constants/gql/ctf";
import { ARRAY_OF_LEVEL } from "../constants/level";
import { ARRAY_OF_COUNTRY } from "../constants/country";
import { TAG_COLORS } from "../constants/tagColor";
import { Tag } from "../constants/types";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const store = useStore();
    const user = computed(() => store.state.user);
    const currentStep = computed(() => store.state.step);
    const router = useRouter();
    const link = ref<string | null>(null);
    const modifyProfile = ref<boolean>(false);
    const dropdownIsActive = ref<string>("");
    const isChangePasswordActive = ref<boolean>(false);
    const searchedTags = ref<string>("");
    const userCtfList = ref<string[]>([]);
    const oldPassword = ref("");
    const newPassword = ref("");
    const newBisPassword = ref("");
    const error = ref("");

    const level = ref<string>(store.state.user.level);
    const description = ref<string>(store.state.user.description);
    const language = ref<string[]>(store.state.user.language || []);
    const skills = ref<string[]>(store.state.user.skills || []);
    const fieldUpdated = ref<string[]>([]);

    const { mutate } = useMutation(UPDATE_PROFILE_MUTATION, {
      refetchQueries: [{ query: FIND_TAGS }]
    });
    const { mutate: mutatePassword } = useMutation(UPDATE_PASSWORD_MUTATION);
    const { loading: ctfLoading, result: ctfResult } = useQuery(FIND_CFT_QUERY);
    const {
      loading: tagLoading,
      onResult: onTagResult,
      result: tagResult
    } = useQuery(FIND_TAGS);
    const tagsWithColor = ref<Tag[]>(
      tagResult.value ? tagResult.value.findTags : []
    );

    onMounted(async () => {
      link.value = await getLink(store.state.user.hashedUsername);
      window.addEventListener("click", () => {
        dropdownIsActive.value = "";
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", () => {
        dropdownIsActive.value = "";
      });
    });

    onTagResult(({ data }) => {
      tagsWithColor.value = data.findTags;
    });

    const updateProfile = async (field: string) => {
      try {
        const { data } = await mutate({
          input: {
            language: language.value,
            description: description.value,
            skills: skills.value,
            level: level.value
          }
        });
        store.commit("setUser", data.update);
        modifyProfile.value = false;
        level.value = store.state.user.level;
        description.value = store.state.user.description;
        language.value = store.state.user.language || [];
        skills.value = store.state.user.skills || [];
        fieldUpdated.value.push(field);
      } catch (er) {
        alert("An Error occured");
      }
    };

    const sendPasswordChange = async () => {
      if (newPassword.value.length < 8) {
        error.value = "Your password should be at least 8 characters";
      } else if (newPassword.value != newBisPassword.value) {
        error.value = "New passwords doesn't match";
      } else {
        try {
          const { data } = await mutatePassword({
            input: {
              oldPassword: oldPassword.value,
              newPassword: newPassword.value
            }
          });
          if (data != null) {
            isChangePasswordActive.value = false;
            error.value = "";
          }
        } catch (er) {
          error.value = "Old Password is incorrect";
        }
      }
    };

    const addLanguage = (event: Event) => {
      const { value } = event.target as HTMLInputElement;
      const newLanguage = [...language.value];

      if (!language.value.includes(value) && value != "-") {
        newLanguage.push(value);
        language.value = newLanguage;
        updateProfile("language");
      }
    };

    const removeLanguage = (lang: string) => {
      const index = language.value.indexOf(lang);
      const duplicateLanguage = [...language.value];

      duplicateLanguage.splice(index, 1);
      language.value = duplicateLanguage;
      updateProfile("language");
    };

    const addSkills = () => {
      const result = tagResult.value.findTags.find(
        (tag: Tag) => tag.name === searchedTags.value
      );

      if (
        !skills.value.includes(capitalize(searchedTags.value)) &&
        searchedTags.value.length > 2
      ) {
        const allSkills = [...skills.value];

        if (result) {
          allSkills.push(result.name);
        } else {
          allSkills.push(capitalize(searchedTags.value));
        }
        skills.value = allSkills;
        searchedTags.value = "";
        updateProfile("skill");
      }
    };

    const removeSkills = (skill: string) => {
      const index = skills.value.findIndex((el: string) => el === skill);
      const allSkills = [...skills.value];

      allSkills.splice(index, 1);
      skills.value = allSkills;
      updateProfile("skill");
    };

    const findSearchedTags = () => {
      const search = (search = "", value: string) => {
        return (value || "").toLowerCase().indexOf(search.toLowerCase()) !== -1;
      };

      return tagResult.value.findTags.filter((el: Tag) => {
        return search(searchedTags.value, el.name);
      });
    };

    const findTagColor = (tagName: string) => {
      const result = tagsWithColor.value.find(
        (tag: Tag) => tag.name === tagName
      );

      if (result) {
        return result.color;
      } else {
        const color = TAG_COLORS[Math.floor(Math.random() * 5)];
        const prevTagResult = [...tagsWithColor.value];
        prevTagResult.push({
          name: tagName,
          color
        });

        tagsWithColor.value = prevTagResult;

        return color;
      }
    };

    const findCtfById = (ctfId: string) => {
      const ctf = ctfResult.value.findCtf.find((el: any) => el.id === ctfId);

      return ctf.title;
    };

    const prevAction = () => {
      store.commit("updateStep", currentStep.value - 1);
      router.back();
    };

    const finishTour = () => {
      store.commit("updateStep", null);
    };

    const route = (url: string) => {
      router.push(url);
    };

    return {
      modifyProfile,
      link,
      user,
      level,
      language,
      description,
      isChangePasswordActive,
      sendPasswordChange,
      oldPassword,
      newPassword,
      newBisPassword,
      error,
      searchedTags,
      findCtfById,
      userCtfList,
      updateProfile,
      findSearchedTags,
      addLanguage,
      removeLanguage,
      dropdownIsActive,
      arrayOfLevel: ARRAY_OF_LEVEL,
      arrayOfCountry: ARRAY_OF_COUNTRY,
      tagLoading,
      tagResult,
      ctfLoading,
      ctfResult,
      skills,
      addSkills,
      removeSkills,
      findTagColor,
      currentStep,
      prevAction,
      finishTour,
      tagsWithColor,
      route,
      fieldUpdated
    };
  }
});
