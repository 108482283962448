export const ARRAY_OF_COUNTRY = [
  "-",
  "English",
  "French",
  "German",
  "Greek",
  "Indian",
  "Italian",
  "Spanish"
];
