import gql from "graphql-tag";

export const FIND_TAGS = gql`
  query FindTags {
    findTags {
      name
      color
    }
  }
`;
